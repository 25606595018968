import React from 'react'
import './Homepage.css'
import { FaCheckCircle } from "react-icons/fa";
import scr1 from './Images/sc.webp'
// import scr2 from './Images/sc2.webp'
// import scr3 from './Images/sc3.png'
// import scr4 from './Images/sc4.webp'
import scr2 from '../components/Images/sc2.jpg'
import scr3 from '../components/Images/sc3.jpg'
import scr4 from '../components/Images/sc4.jpg'
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";



const Feature = () => {
    return (
        <>
       
            <div className='Main-feature'>
                <div className='feature-heading'>
                    <h2>Features that will capture your heart</h2>
                    <span>
                        Say adieu to lackluster WhatsApp interactions.</span>
                    <span>
                        Welcome a new age of productivity with Botwala.
                    </span>
                </div>


                <div className='Teamchart'>
                    <div className='Teamimg'>
                        <img src={scr1} alt="" className="screen1" />
                    </div>



                    <div className='Teamcharttext'>
                        <div className='Teamchat'>
                            <h2>
                                Group Chat</h2>
                            <div className='Teamtext'> <p>Facilitate seamless cooperation among your entire team across one or multiple WhatsApp numbers.





                            </p></div>
                            <div className='Checktext'>
                                <div className='itext'>
                                    <FaCheckCircle className='checki' />
                                    <p><span>Invite an unlimited number of agents:</span> expand your team as your business grows.</p>
                                </div>

                                <div className='itext'>
                                    <FaCheckCircle className='checki' />
                                    <p> <span>Establish roles for your agents: </span>structure your team's hierarchy within Botwala.</p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checki' />
                                    <p> <span>Determine chat privileges for each role:</span> regulate chat access and actions according to designated roles.





                                    </p>
                                </div>

                                <div className='itext'>
                                    <FaCheckCircle className='checki' />
                                    <p> <span>Allocate chats to designated agents: </span>automate or manually assign chats or groups to individual team members.




                                    </p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checki' />
                                    <p><span>Monitor your agents' activities at any moment: </span>our collaborative chat feature enables you to track if an agent is reading or typing in a conversation to prevent overlap.





                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div>
                    <hr className='Lineone'></hr>
                </div>




                <div className='Teamchart'>

                    <div className='Teamcharttext'>
                        <div className='Teamchat'>
                            <h2>
                                Campaign Messaging</h2>
                            <div className='Teamtext'> <p>Effortlessly expand your reach with tailored, scheduled campaigns. No technical expertise needed – just a few clicks away.</p></div>
                            <div className='Checktext'>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p><span>Simplify contact importing:</span> Bring in a list of desired recipients from CSV, Excel, Google Sheets, or choose contacts directly from the internal CRM.





                                    </p>
                                </div>

                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p> <span>Elevate your messaging:</span> Enhance your communication by combining text with multimedia elements like images or videos.</p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p> <span>Effortlessly monitor delivery:</span> Keep tabs on recipients who received and read your campaign message. Receive notifications upon campaign completion.</p>
                                </div>

                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p> <span>Personalize your messages: </span>Use template variables to personalize and dynamize your text messages with recipient-specific information. </p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p><span>Exceptionally effective: </span>Differentiate yourself from competitors by automating message delivery through our campaigns, accommodating up to 2000 recipients per campaign.</p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p><span>Manage with precision and versatility:</span> Schedule campaigns for specific dates and times, dispatch messages to any WhatsApp user or group chat. Pause, resume, or cancel at your discretion.





                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className='Teamimg1'>
                        <img src={scr2} alt="" className="screen1" />
                    </div>

                </div>


                <div>
                    <hr className='Lineone'></hr>
                </div>



                <div className='Teamchart'>
                    <div className='Teamimg'>
                        <img src={scr3} alt="" className="screen1" />
                    </div>



                    <div className='Teamcharttext'>
                        <div className='Teamchat'>
                            <h2>
                                Statistical Analysis</h2>
                            <div className='Teamtext'> <p>
                                Monitor your team's productivity and glean insights into customer engagement on WhatsApp.</p></div>
                            <div className='Checktext'>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p><span>Assess your team's performance:</span>Access detailed data regarding your agents' activity on WhatsApp.





                                    </p>
                                </div>

                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p> <span>Comprehensive WhatsApp usage statistics:</span> Receive concrete data on customer interaction with your business through WhatsApp.</p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p> <span>Evaluate your agents: </span>Quickly compare and analyze your agents' performance in one view.










                                    </p>
                                </div>

                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p> <span>
                                        Assess your agents:</span> Easily compare and analyze performance across your team at a glance</p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p><span>Export analytics reports effortlessly:</span> Download data in PDF, CSV, or JSON format, or have it sent directly to your email.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div>
                    <hr className='Lineone'></hr>
                </div>


                <div className='Teamchart'>

                    <div className='Teamcharttext'>
                        <div className='Teamchat'>
                            <h2>Comprehensive CRM Solution</h2>
                            <div className='Teamtext'> <p>Automatically sync WhatsApp contacts for seamless integration. Effortlessly manage, update, and import contacts within a fully integrated CRM system for all your WhatsApp customers.





                            </p></div>
                            <div className='Checktext'>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p><span>Modify, enhance, or update contact information: </span>Include pertinent details in your contacts' profiles to address them accurately.





                                    </p>
                                </div>

                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p> <span>Apply metadata and labels: </span>Attach metadata and labels to your contacts for improved organization and targeted communication.





                                        .</p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p> <span>Include internal notes: </span>Document important details about your contacts for reference.





                                    </p>
                                </div>

                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p> <span>Review past interactions with your contacts: </span>Ensure you never miss a customer conversation and deliver exceptional support on WhatsApp.</p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p><span>Exceptionally effective:</span> Differentiate yourself from competitors by automating message delivery through our campaigns, accommodating up to 2000 recipients per campaign.





                                    </p>
                                </div>
                                <div className='itext'>
                                    <FaCheckCircle className='checkic1' />
                                    <p><span>Manage with precision and versatility:</span> Schedule campaigns for specific dates and times, dispatch messages to any WhatsApp user or group chat. Pause, resume, or cancel at your discretion.





                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className='Teamimg'>
                        <img src={scr4} alt="" className="screen1" />
                    </div>

                </div>


                <div>
                    <hr className='Linetwo'></hr>
                </div>







            </div>

        </>
    )
}

export default Feature