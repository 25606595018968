import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineMessage } from "react-icons/ai";
import './Signup.css';

function Signup() {
    const navigate = useNavigate();

    // Form states
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [submitted, setSubmitted] = useState(false);

    // Error states
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

   // Validation functions
const validateUsername = () => {
    if (username.trim() === '') {
        setUsernameError('Username is required.');
        return false;
    } else if (!/^[a-zA-Z0-9]+$/.test(username)) {
        setUsernameError('Username must contain a combination of letters and numbers.');
        return false;
    } else {
        setUsernameError('');
        return true;
    }
};

    const validatePassword = () => {
        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
            return false;
        } else {
            setPasswordError('');
            return true;
        }
    };

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Invalid email format.');
            return false;
        } else {
            setEmailError('');
            return true;
        }
    };

    const validatePhone = () => {
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(phone)) {
            setPhoneError('Invalid phone number. Must be 10 digits.');
            return false;
        } else {
            setPhoneError('');
            return true;
        }
    };

    // Submit event
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate each field before submission
        const isUsernameValid = validateUsername();
        const isPasswordValid = validatePassword();
        const isEmailValid = validateEmail();
        const isPhoneValid = validatePhone();

        // If any field is invalid, prevent form submission
        if (!isUsernameValid || !isPasswordValid || !isEmailValid || !isPhoneValid) {
            return;
        }

        // Object to pass
        const formData = {
            username,
            password,
            email,
            phone,
        };

        try {
            // Post data to the Google Sheet
            await axios.post('https://sheet.best/api/sheets/55b20f79-9450-4f9a-94e1-9aa46e797b7d', formData);

            // Clear form fields after successful submission
            setUsername('');
            setPassword('');
            setEmail('');
            setPhone('');

            // Navigate to the login page after successful signup
            navigate('/login');

            setSubmitted(true);  // Set submitted state to true
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    return (
        <div className="login1">
            <div className="company-logo">
                <Link to="/">
                    <div className="platform plat">
                        <div className="logo-create lc">
                            <div className="logobox1 lg-box1">
                                <div className='Unlimited1 un '>
                                    <AiOutlineMessage className='messagebox mg' />
                                </div>
                                <div className="logotext1 lgt">
                                    <p className="lgtp"><b>BOT</b>WALA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <h1>Signup</h1>

            <form autoComplete="off" className='form-group fg' id='form-content-2' onSubmit={handleSubmit}>
                <input
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    onBlur={validateUsername}
                    placeholder="Username"
                    className="username"
                />
                <div className="error-message">{usernameError}</div>

                <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    onBlur={validatePassword}
                    placeholder="Password (min. 8 characters)"
                    className="username"
                />
                <div className="error-message">{passwordError}</div>

                <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    onBlur={validateEmail}
                    placeholder="Email"
                    className="username"
                />
                <div className="error-message">{emailError}</div>

                <input
                    type="tel"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    onBlur={validatePhone}
                    placeholder="Phone"
                    className="username"
                />
                <div className="error-message">{phoneError}</div>

                <div className="sub-btn">
                    <button type="submit" className="login-btn">
                        Signup
                    </button>
                </div>
            </form>

            <br />
            <div className="upper">
                <p>Already have an account?</p>
                <Link to="/login">Login here</Link>
            </div>
        </div>
    );
}

export default Signup;
