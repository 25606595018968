import React, { useRef, useState, useEffect } from 'react';
import './wati.css';
import barriy from './Images/brk.webp';
import way from './Images/ups.webp';
import communication from './Images/sscale.webp';
import multiple from './Images/chhnl.webp';
import { Link } from 'react-router-dom';

export const Wati = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const slides = useRef(null);
    
    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex === slides.current.children.length - 1 ? 0 : prevIndex + 1));
        }, 2000); // Change slide every 5 seconds (5000 milliseconds)

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="Engagement">
                <div className="barriers">
                    <h1>Select the benefits of Botwala</h1>
                    <p>Engage your prospects efficiently with Botwala through the WhatsApp Business API</p>
                </div>

                <div id="carouselExample" className="carousel slide">
                    <div className="carousel-inner" id="mainslid" ref={slides}>
                        <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
                            <div id="slid">
                                <div className="engage" >
                                    <img src={barriy} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Eliminating obstacles to<br /> participation</h3>
                                        <p>
                                        Craft personalized messages based on individual preferences, behaviors, and demographics to cultivate a heightened level of response rates.
                                        </p>
                                    </div>
                                </div>

                                <div className="better-way">
                                    <img src={way} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>A better way to<br /> Level Up</h3>
                                        <p>
                                        Attain superior ROI compared to other WhatsApp BSPs by leveraging the efficiency of multiple users on a single number to elevate your operations.

                                        </p>
                                    </div>
                                </div>

                                <div className="thousands">
                                    <img src={communication} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Efficiently communication<br />efforts</h3>
                                        <p>
                                            Quickly initiate and handle conversations with thousands of customers and prospects through automation, chatbots, and tailored workflows.
                                        </p>
                                    </div>
                                </div>
                                {/* Add other slides as needed */}
                            </div>
                        </div>
                        <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
                            <div id="slid">
                                <div className="better-way">
                                    <img src={way} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>A better way to<br /> Level Up</h3>
                                        <p>
                                        Attain superior ROI compared to other WhatsApp BSPs by leveraging the efficiency of multiple users on a single number to elevate your operations.

                                        </p>
                                    </div>
                                </div>

                                <div className="engage" >
                                    <img src={barriy} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Eliminating obstacles to<br /> participation</h3>
                                        <p>
                                        Craft personalized messages based on individual preferences, behaviors, and demographics to cultivate a heightened level of response rates.
                                        </p>
                                    </div>
                                </div>
                                <div className="thousands">
                                    <img src={communication} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Efficiently communication<br />efforts</h3>
                                        <p>
                                            Quickly initiate and handle conversations with thousands of customers and prospects through automation, chatbots, and tailored workflows.
                                        </p>
                                    </div>
                                </div>
                                {/* Add other slides as needed */}
                            </div>
                        </div>
                        <div className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
                            <div id="slid">
                                <div className="thousands">
                                    <img src={communication} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Efficiently communication<br />efforts</h3>
                                        <p>
                                            Quickly initiate and handle conversations with thousands of customers and prospects through automation, chatbots, and tailored workflows.
                                        </p>
                                    </div>
                                </div>

                                <div className="engage" >
                                    <img src={barriy} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Eliminating obstacles to<br /> participation</h3>
                                        <p>
                                        Craft personalized messages based on individual preferences, behaviors, and demographics to cultivate a heightened level of response rates.
                                        </p>
                                    </div>
                                </div>

                                <div className="programs">
                                    <img src={multiple} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Merge multiple channels<br /> into one.</h3>
                                        <p>
                                        Enhance ROI by 10x leveraging Facebook and Instagram for lead generation, followed by a seamless transition to WhatsApp for effective lead nurturing.
                                        </p>
                                    </div>
                                </div>
                                {/* Add other slides as needed */}
                            </div>
                        </div>
                        <div className={`carousel-item ${activeIndex === 3 ? 'active' : ''}`}>
                            <div id="slid">
                                <div className="programs">
                                    <img src={multiple} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Merge multiple channels<br /> into one.</h3>
                                        <p>
                                        Enhance ROI by 10x leveraging Facebook and Instagram for lead generation, followed by a seamless transition to WhatsApp for effective lead nurturing.
                                        </p>
                                    </div>
                                </div>

                                <div className="thousands">
                                    <img src={communication} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Efficiently communication<br />efforts</h3>
                                        <p>
                                            Quickly initiate and handle conversations with thousands of customers and prospects through automation, chatbots, and tailored workflows.
                                        </p>
                                    </div>
                                </div>

                                <div className="engage" >
                                    <img src={barriy} alt="" className="bairy" />
                                    <div className="break">
                                        <h3>Eliminating obstacles to<br /> participation</h3>
                                        <p>
                                        Craft personalized messages based on individual preferences, behaviors, and demographics to cultivate a heightened level of response rates.
                                        </p>
                                    </div>
                                </div>
                                {/* Add other slides as needed */}
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                {/* <Link to="/faq">FAQ</Link> */}
            </div>
        </>
    );
};

export default Wati;
