import React from 'react'
import '../components/dashboard.css'

const Dashbord = () => {
  return (
    <div>
      <h2 className='login-visit'>Thanks for visiting, You have sucessfully Login</h2>
    </div>
  )
}

export default Dashbord
