import React from 'react'
import '../components/Privacy.css'
import { HiCheckCircle } from "react-icons/hi";


const Privacypolicy = () => {
    return (
        <>
        <div className='privacy-policy-page'>
            <div className="privacy-policy">
                <div className="purpose">
                   
                    <div className="personal-info">
                    <div className='privacy-head'><h1>
                            Privacy Policy
                        </h1></div>
                        
                        <p>
                            The protection of personal information for all users, including customers and visitors of our website www.Botwala.com, along with those utilizing our products or services, is of utmost importance to us.





                        </p>
                    </div>
                </div>

                <div className="maintains">
                    <p>
                        The privacy practices governing the collection, use, sharing, and maintenance of information from users ("Users", "you") of the https://www.Botwala.com/privacy-policy website ("Site") are outlined in this Privacy Policy. It pertains to all products and services offered by Botwala. Throughout this document, "we", "us", and "our" refer to Botwala and its affiliated corporate entities.

                    </p>
                </div>

                <div className="what-information">
                    <h3>

                        What types of information do we process?
                    </h3>
                </div>

                <div className="our-website">
                    <h3>
                        <i>
                            Information submitted to us through our website.</i>
                    </h3>
                    <p>
                        We process all information provided to us via our website ("our site"), whether through online forms, telephone calls, emails, or any other communication channels. This includes details shared during client registration, product/service inquiries, interactions with social media features integrated into our website, or when reporting website-related issues.



                    </p>
                    <div className="varies-between">
                        <p>

                            The types of personal data we handle differ among clients but may encompass:
                        </p>
                    </div>
                    <div className="client-data">
                        <HiCheckCircle id='client' />
                        <span>Name</span>
                    </div>
                    <div className="client-data">
                        <HiCheckCircle id='client' />
                        <span>Job-title</span>
                    </div>
                    <div className="client-data">
                        <HiCheckCircle id='client' />
                        <span>Email Address</span>
                    </div>
                    <div className="client-data">
                        <HiCheckCircle id='client' />
                        <span>Telephone Number</span>
                    </div>
                    <div className="client-data">
                        <HiCheckCircle id='client' />
                        <span>
                            Device and browser details</span>
                    </div>
                    <div className="client-data">
                        <HiCheckCircle id='client' />
                        <span>Location Data</span>
                    </div>
                    <div className="client-data">
                        <HiCheckCircle id='client' />
                        <span>
                            Data regarding your website and platform browsing behaviors</span>
                    </div>
                </div>
                <div className="information-provide">
                    <h3>
                        <i>

                            Data supplied by our clients.
                        </i>
                    </h3>
                    <p>

                        The types of personal data we handle vary among clients, but may encompass:                    </p>

                    <div className="client-info">
                        <HiCheckCircle id='client' />
                        <span>Name</span>
                    </div>
                    <div className="client-info">
                        <HiCheckCircle id='client' />
                        <span>Job-title</span>
                    </div>
                    <div className="client-info">
                        <HiCheckCircle id='client' />
                        <span>Business Address</span>
                    </div>
                    <div className="client-info">
                        <HiCheckCircle id='client' />
                        <span>Business Email Address</span>
                    </div>
                    <div className="client-info">
                        <HiCheckCircle id='client' />
                        <span>Business telephone number(s)</span>
                    </div>
                    <div className="client-info">
                        <HiCheckCircle id='client' />
                        <span>Business financial information</span>
                    </div>
                    <div className="client-info">
                        <HiCheckCircle id='client' />
                        <span>Business Type</span>
                    </div>
                    <div className="client-info">
                        <HiCheckCircle id='client' />
                        <span>Business Size</span>
                    </div>
                    <div className="client-info">
                        <HiCheckCircle id='client' />
                        <span>Location Data</span>
                    </div>
                    <div className="third-party">
                        <p>
                            In the event of integrating third-party marketplaces such as Shopify stores into Botwala, Botwala may collect and/or process additional categories of data.





                        </p>
                        <div className="inventory">
                            <HiCheckCircle id='client' />
                            <span>
                                Data regarding inventory
                            </span>
                        </div>
                        <div className="inventory">
                            <HiCheckCircle id='client' />
                            <span>Order Information</span>
                        </div>
                        <div className="inventory">
                            <HiCheckCircle id='client' />
                            <span>
                                Customer data</span>
                        </div>
                    </div>
                    <div className="payment-links">
                        <p>
                            When employing Botwala Payment Links, additional specific information about your business may be collected, including, but not limited to, the following categories:






                        </p>
                        <div className="inventory-process">
                            <HiCheckCircle id='client' />
                            <span>
                                Information regarding payments
                            </span>
                        </div>
                        <div className="inventory-process">
                            <HiCheckCircle id='client' />
                            <span>
                                Online selling platform
                            </span>
                        </div>

                    </div>
                    <div className="book-demo">
                        <p>
                            When you submit a "Book a Demo" form on our website, we may request further information from you, including:

                        </p>
                        <div className="may-collect">
                            <HiCheckCircle id='client' />
                            <span>Company Size
                            </span>
                        </div>
                        <div className="may-collect">
                            <HiCheckCircle id='client' />
                            <span>
                                Financial details related to the business</span>
                        </div>

                    </div>
                </div>


                <div className="your-rights">
                    <h3>
                        Your privileges
                    </h3>
                </div>
                <div className='access-text'>

                    <div className="request-access">
                        <HiCheckCircle id='client' />
                        <span>
                            You have the right to request access to your personal data through a "data subject access request." This allows you to obtain a copy of the personal data we have concerning you and verify its lawful processing.


                        </span>
                    </div>
                    <div className="request-access">
                        <HiCheckCircle id='client' />
                        <span>
                            You have the right to request rectification, correction, or updating of any personal data we hold about you. This allows you to correct any incomplete or inaccurate information in our records.
                        </span>
                    </div>
                    <div className="request-access">
                        <HiCheckCircle id='client' />
                        <span>
                            You may request that the personal data you provided be transferred to you in a structured, commonly used, and machine-readable format.


                        </span>


                    </div>
                    <div className="request-access">
                        <HiCheckCircle id='client' />
                        <span>
                            You have the right to request the erasure of personal data. This allows you to ask us to delete or remove personal data when there is no valid reason for us to continue processing it. You also have the right to request deletion or removal of personal data when you have exercised your right to object to processing (see below).
                        </span>
                    </div>
                    <div className="request-access">
                        <HiCheckCircle id='client' />
                        <span>
                            You have the right to request the restriction of processing of your personal data. This allows you to ask us to suspend the processing of personal data about you, for example, if you want us to verify its accuracy or the purpose for processing it.

                        </span>



                    </div>
                    <div className="request-access">
                        <HiCheckCircle id='client' />
                        <span>
                            You can object to the processing of your personal data in specific situations. This right may be applicable when the processing of your personal data is grounded on the legitimate interests of Botwala.
                        </span>
                    </div>
                </div>

                <div className="your-customer">
                    <h3>
                        Details concerning your customers</h3>
                </div>
                <div className='access-heading'>

                    <div className="request-dainy">

                        <span>
                            We gather and utilize personal data concerning your customers. Typically, we only gather and utilize this personal data as instructed by you. We will never utilize your customers' information for independent marketing or advertising to them unless they are directly utilizing our applications or services as well.

                        </span>
                    </div>
                    <div className="request-dainy">

                        <span>
                            It's essential to ensure that your customers comprehend how both you (and we, acting on your behalf) collect and process their personal information.


                        </span>
                    </div>
                    <div className="request-dainy">

                        <span>
                            Furthermore, if you gather any sensitive personal information from your customers (such as data regarding health, race, ethnicity, genetics, biometrics, trade union membership, political opinions, philosophical or religious beliefs, criminal history, or sexual interests), it's imperative to obtain explicit affirmative consent from your customers to utilize and process this information.

                        </span>
                    </div>

                </div>

                <div className="contact-us">
                    <h3>
                        Contact
                    </h3>
                    <p>
                    For inquiries regarding data protection and this privacy notice, you can reach out to us via email at support@Botwala.com.





</p>
                </div>

            </div>
            </div>
        </>
    )
}

export default Privacypolicy