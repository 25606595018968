import React, { useState } from 'react';
import './GetApi.css';
import msgman from '../components/Images/apiman.png';
import axios from 'axios';
import { useEffect } from 'react';
import Navbar from './Navbar';

function GetApi() {
    // Form states
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [location, setLocation] = useState('');
    const [contactPurpose, setContactPurpose] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [data, setData] = useState([]);

    // Error states
    const [nameError, setNameError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [companySizeError, setCompanySizeError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [contactPurposeError, setContactPurposeError] = useState('');

    // Submit event
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate fields
        let valid = true;

        // Validate Company Name
        if (!name) {
            setNameError('Company Name is required');
            valid = false;
        } else {
            setNameError('');
        }

        // Validate Company Size
        if (!companySize) {
            setCompanySizeError('Company Size is required');
            valid = false;
        } else {
            setCompanySizeError('');
        }

        // Validate Mobile Number
        if (!/^\d{10}$/.test(mobile)) {
            setMobileError('Mobile number must be 10 digits');
            valid = false;
        } else {
            setMobileError('');
        }

        // Validate Location
        if (!location) {
            setLocationError('Please select a location');
            valid = false;
        } else {
            setLocationError('');
        }

        // Validate Contact Purpose
        if (!contactPurpose) {
            setContactPurposeError('Please select the purpose of contacting');
            valid = false;
        } else {
            setContactPurposeError('');
        }

        if (!valid) {
            return; // Do not proceed with the submission if there are validation errors
        }

        // Object to pass
        const formData = {
            name,
            mobile,
            location,
            companySize,
            contactPurpose,
        };

        try {
            // Post data to the Google Sheet
            await axios.post('https://sheet.best/api/sheets/204a990b-679e-40fc-a897-73e616707c78', formData);

            // Clear form fields after successful submission
            setName('');
            setMobile('');
            setLocation('');
            setCompanySize('');
            setContactPurpose('');

            // Fetch updated data after submission
            getData();
            setSubmitted(true);  // Set submitted state to true
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    // Get data from the Google Sheet
    const getData = async () => {
        try {
            const response = await axios.get('https://sheet.best/api/sheets/204a990b-679e-40fc-a897-73e616707c78');
            setData(response.data);
        } catch (error) {
            console.error('Error getting data:', error);
        }
    };

    // Trigger getData function on component mount
    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {/* <Navbar/> */}
            <div className='get-api-main'>
                <div className='get-api-session'>
                    <div className='get-top-row'>
                        <span>Connect with our skilled team for a complimentary consultation and assessment of your messaging strategy, to meet your unique needs and objectives.</span>
                    </div>
                    <div className='get-form-content'>
                        <div className='gets-main'>
                            <div className='get-api-form'>
                                <h1 className='in-with'>Interact with us</h1>
                                <div className='gets-img'>
                                    <img src={msgman} className='msgman-img' alt='msgman' />
                                </div>
                            </div>
                        </div>
                        <div className='gets-form'>
                            <div className='get-form'>
                                {submitted ? (
                                    <div className='api-form3'>
                                        <div className='api-form2-content sub-msg'>
                                            <h1>Thank You!</h1>
                                            <span className='recieve-msg'>Your Message have been Received</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='api-form1'>
                                        <div className='api-form1-content'>
                                            <span>All fields with * are required.</span>
                                            <form autoComplete="off" className='form-group' id='form-content-1' onSubmit={handleSubmit}>

                                                <input
                                                    id='form-fields-4'
                                                    type='text'
                                                    className='form-control form-fields-d'
                                                    placeholder='Company Name *'
                                                    onChange={(e) => setName(e.target.value)}
                                                    value={name}
                                                />
                                                <span className='error-message'>{nameError}</span>

                    
                                                 <select
                                                    id='form-fields-4'
                                                    className='form-control form-fields-d'
                                                    onChange={(e) => setCompanySize(e.target.value)}
                                                    value={companySize}
                                                >
                                                    <option value='' disabled selected>
                                                        Please select a size *
                                                    </option>
                                                    <option className='cn' value='Large'>Large</option>
                                                    <option className='cn' value='Medium'>Medium</option>
                                                    <option className='cn' value='Small'>Small</option>
                                    
                                                </select>
                                                <span className='error-message'>{companySizeError}</span>

                                                <input
                                                    id='form-fields-5'
                                                    className='form-control form-fields-d'
                                                    type='number'
                                                    placeholder='Mobile *'
                                                    onChange={(e) => setMobile(e.target.value)}
                                                    value={mobile}
                                                />
                                                <span className='error-message'>{mobileError}</span>

                                                <select
    id='form-fields-6'
    className='form-control form-fields-d'
    onChange={(e) => setLocation(e.target.value)}
    value={location}
>
    <option value='' disabled defaultValue>
        Please select a location *
    </option>
    <option className='cn' value='Afghanistan'>Afghanistan</option>
    <option className='cn' value='Albania'>Albania</option>
    <option className='cn' value='Algeria'>Algeria</option>
    <option className='cn' value='Andorra'>Andorra</option>
    <option className='cn' value='Angola'>Angola</option>
    <option className='cn' value='Antigua and Barbuda'>Antigua and Barbuda</option>
    <option className='cn' value='Argentina'>Argentina</option>
    <option className='cn' value='Armenia'>Armenia</option>
    <option className='cn' value='Australia'>Australia</option>
    <option className='cn' value='Austria'>Austria</option>
    <option className='cn' value='Azerbaijan'>Azerbaijan</option>
    <option className='cn' value='Bahamas'>Bahamas</option>
    <option className='cn' value='Bahrain'>Bahrain</option>
    <option className='cn' value='Bangladesh'>Bangladesh</option>
    <option className='cn' value='Barbados'>Barbados</option>
    <option className='cn' value='Belarus'>Belarus</option>
    <option className='cn' value='Belgium'>Belgium</option>
    <option className='cn' value='Belize'>Belize</option>
    <option className='cn' value='Benin'>Benin</option>
    <option className='cn' value='Bhutan'>Bhutan</option>
    <option className='cn' value='Bolivia'>Bolivia</option>
    <option className='cn' value='Bosnia and Herzegovina'>Bosnia and Herzegovina</option>
    <option className='cn' value='Botswana'>Botswana</option>
    <option className='cn' value='Brazil'>Brazil</option>
    <option className='cn' value='Brunei'>Brunei</option>
    <option className='cn' value='Bulgaria'>Bulgaria</option>
    <option className='cn' value='Burkina Faso'>Burkina Faso</option>
    <option className='cn' value='Burundi'>Burundi</option>
    <option className='cn' value='Cambodia'>Cambodia</option>
    <option className='cn' value='Cameroon'>Cameroon</option>
    <option className='cn' value='Canada'>Canada</option>
    <option className='cn' value='Cape Verde'>Cape Verde</option>
    <option className='cn' value='Central African Republic'>Central African Republic</option>
    <option className='cn' value='Chad'>Chad</option>
    <option className='cn' value='Chile'>Chile</option>
    <option className='cn' value='China'>China</option>
    <option className='cn' value='Colombia'>Colombia</option>
    <option className='cn' value='Comoros'>Comoros</option>
    <option className='cn' value='Congo'>Congo</option>
    <option className='cn' value='Costa Rica'>Costa Rica</option>
    <option className='cn' value='Croatia'>Croatia</option>
    <option className='cn' value='Cuba'>Cuba</option>
    <option className='cn' value='Cyprus'>Cyprus</option>
    <option className='cn' value='Czech Republic'>Czech Republic</option>
    <option className='cn' value='Denmark'>Denmark</option>
    <option className='cn' value='Djibouti'>Djibouti</option>
    <option className='cn' value='Dominica'>Dominica</option>
    <option className='cn' value='Dominican Republic'>Dominican Republic</option>
    <option className='cn' value='East Timor'>East Timor</option>
    <option className='cn' value='Ecuador'>Ecuador</option>
    <option className='cn' value='Egypt'>Egypt</option>
    <option className='cn' value='El Salvador'>El Salvador</option>
    <option className='cn' value='Equatorial Guinea'>Equatorial Guinea</option>
    <option className='cn' value='Eritrea'>Eritrea</option>
    <option className='cn' value='Estonia'>Estonia</option>
    <option className='cn' value='Eswatini'>Eswatini</option>
    <option className='cn' value='Ethiopia'>Ethiopia</option>
</select>

                                                <span className='error-message'>{locationError}</span>

                                                <select
    id='form-fields-7'
    className='form-control form-fields-d'
    onChange={(e) => setContactPurpose(e.target.value)}
    value={contactPurpose}
>
    <option value='' disabled defaultValue>
        Select the purpose of contacting *
    </option>
    <option className='cn' value='Requirement For Whatsapp api'>Necessity for WhatsApp API</option>
    <option className='cn' value='For whatsapp Automation'>For WhatsApp Bot Integration</option>
    <option className='cn' value='For Customer support'>For Customer Assistance</option>
    <option className='cn' value='For E-Commerce'>For E-Commerce</option>
    <option className='cn' value='For General Inquiry'>General Inquiry</option>
    <option className='cn' value='For Partnership Opportunities'>Partnership Opportunities</option>
    <option className='cn' value='For Technical Support'>Technical Support</option>
    <option className='cn' value='For Product Information'>Product Information</option>
    <option className='cn' value='For Marketing Collaboration'>Marketing Collaboration</option>
    <option className='cn' value='For Feedback and Suggestions'>Feedback and Suggestions</option>
    <option className='cn' value='For Other Purposes'>Other Purposes</option>
</select>

                                                <span className='error-message'>{contactPurposeError}</span>

                                                <button type='submit' className='next-btn'>Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GetApi;
