import React from 'react'
import './partnership.css'
import partnerImg from './Images/PartnerImg.png'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'
const CustomPartnershipComponent = () => { 
    return (
      <div>
          <div className='partner' id='BecomeA'>
            <h1>Partnership Networks</h1>
          </div>
        <div className="MainPartnershipPage" >
          
  
        <div className="CustomImgContainer"> 
          <img src={partnerImg} alt="partnerImg" className="CustomPartnerSrc" /> 
          </div>
  
  
          <div className="CustomPartnershipPage"> 
            <p className='customBotwalaP'>Partner with BotWala</p> 
  
            <h2>Join us, grow with WhatsApp's ecosystem and succeed together</h2>
  
            <p>Boost your business value by partnering with <span>BotWala</span> and delighting your customers.</p>
              <div className="CustomButtonCls"> 
              <Link to="/Form" className='Linking'> <button className='customPtnrBtn'>Partner With US
             </button> </Link>
              </div>
              
           
          </div>
         
        </div>
      </div>
    );
  };
  
  export default CustomPartnershipComponent; // Updated export name