import { Route, Routes } from 'react-router-dom';
import NewNavbar from './components/NewNavbar';
import FAQs from './components/FAQs';
import Navbar from './components/Navbar';
import Homepage from './components/Homepage';
import NewNavbar2 from './components/NewNavbar2';
import GetApi from './components/GetApi';
import Pricing from './components/Pricing';
import CaseStudies from './components/CaseStudies';
import Partnership from './components/Partnership';
import Footer from './components/Footer';
import Brands from './components/Brands';
import ThankYou from './components/ThankYou';
import Wati from './components/Wati';
import Form from './components/Form';
import NewForm from './components/NewForm';
import Login from './components/Login';
import Feature from './components/Feature';
import Signup from './components/Signup';
import Dashbord from './components/Dashbord';
import Modal from './components/Modal';
import Homep from './components/Homep';
import ModalForm from './components/ModalForm';
import ScrollToTop from './components/ScrollToTop';
import GetPage from './components/GetPage'
import TermConditions from './components/TermConditions';
import RefundPolicy from './components/RefundPolicy';
import Contact from './components/Contact';
import Privacy from './components/Privacy';


function App() {
  return (
   <>
      {/* <Navbar /> */}
      {/* <Form/> */}
      {/* <Login/> */}
     
      {/* <GetApi/> */}
      {/* <FAQs/> */}
      {/* <Wati/> */}
      {/* <NewNavbar/> */}
      {/* <Homepage/> */}
      {/* <NewNavbar/>
      <NewNavbar/> */}
      {/* <Brands/> */}
      {/* <NewForm/> */}
      {/* <Feature/> */}
      <ScrollToTop/>
      <Navbar/>
     <Routes>
      <Route path='/' element={<Homepage/>}/>
      <Route path='/faq' element={<FAQs/>}/>
      <Route path='/getapi' element={<GetApi/>}/>
      <Route path='/pricing' element={<Pricing/>}/>
      <Route path='/case' element={<CaseStudies/>}/>
      <Route path='/partner' element={<Partnership/>}/>
      <Route path='/Form' element={<Form/>}/>
      <Route path='/feature' element={<Feature/>}/>
      <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<Dashbord />} />
                <Route path="/signup" element={<Signup />} />
                <Route path='/getpage' element={<GetPage/>}/>
                {/* <Route path='/' element={<ModalForm/>}/> */}
                <Route path='/getpage' element={<GetPage/>}/>
                <Route path='/terms' element={<TermConditions/>}/>
                <Route path='/refund' element={<RefundPolicy/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/privacy' element={<Privacy/>}/>
               
                
     </Routes>
     <Footer/>
     {/* <Modal/> */}
{/* <Homepage/> */}
{/* <Pricing/> */}
{/* <CaseStudies/> */}




      {/* <Routes> */}
        {/* <Route path='/' element={<NewNavbar/>}/>
        <Route path='/faqs' element={<FAQs/>}/> */}
        {/* <Route path="/faqss" element={<FAQs/>}/> */}
        {/* Other routes can be added here */}
      {/* </Routes> */}
      {/* <Homepage/> */}
      </>
   
  );
}

export default App;
