import React, { useRef } from 'react'
import './Homepage.css'
import img1 from './Images/1.jpg'
import img2 from './Images/2.jpg'
import img3 from './Images/3.jpg'
import img4 from './Images/4.jpg'
import img6 from './Images/6.jpg'
import img7 from './Images/7.jpg'
import { MdRadioButtonChecked } from "react-icons/md"
import img5 from './Images/5.jpg'
import '../components/Modal.css'
import { FaCheck } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosStar } from "react-icons/io";
import { AiOutlineMessage } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import logo from './Images/b2.png';
import banner from './Images/Poster botwala 1.gif';
import srl1 from './Images/three.svg';
import srl2 from './Images/Changi-airport.svg';
import srl3 from './Images/Emami.svg';
import srl4 from './Images/ITC.svg';
import srl5 from './Images/Killer.svg';
import srl6 from './Images/Oxwhitee.svg';
import srl7 from './Images/Pillup.svg';
import srl8 from './Images/Welspun-logo.svg';
import srl9 from './Images/Za-insure-logo.svg';
import srl10 from './Images/Zoomar.svg';
import srl11 from './Images/edureka.svg';
import srl12 from './Images/f-bank.svg';
import srl13 from './Images/jp.svg';
import srl14 from './Images/mivi.svg';
import srl15 from './Images/swine.svg';
import srl16 from './Images/tiktok.svg';
import srl17 from './Images/weland.svg';
import srl18 from './Images/f-bank.svg';
import Navbar from './Navbar'
import { FaHandHoldingMedical } from "react-icons/fa";
import { GiSpellBook } from "react-icons/gi";
import { RiAdvertisementLine } from "react-icons/ri";
import { FaUsersViewfinder } from "react-icons/fa6";
import { RiCustomerService2Fill } from "react-icons/ri";
import { GiCommercialAirplane } from "react-icons/gi";
import { FaSackDollar } from "react-icons/fa6";
// import wplogo from './Images/watsapp apk.png'
import { IoIosCheckbox } from "react-icons/io";
import LicImg from './Images/LIC img.png'
import SaiFinance from './Images/sai.jpg'
import Fizito from './Images/Fizito img.png'
import pacematic from './Images/paceTC.png'
import pacematicT from './Images/pacematic tech.jpeg'
import healandcarec from './Images/Heal and Care imge.png'
import smart from './Images/saiMart2.png'
import man from './Images/manb.png';
import { FaBook } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { FaCar } from "react-icons/fa6";
import { FaHeart } from "react-icons/fa";
import { FaPlane } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa";
import { IoArrowRedoSharp } from "react-icons/io5";
import { MdTrolley } from "react-icons/md";
import { FaStethoscope } from "react-icons/fa6";
import Wati from './Wati';
import Footer from './Footer'
import Partnership from './Partnership'
import FAQs from './FAQs'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useEffect } from 'react';
import axios from 'axios';



const Homepage = () => { 
     const [show, setShow] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    

    const [formData, setFormData] = useState({
        firstname: '',
        mobile: '',
        location: '',
        companyname: '',
    });

    const [formErrors, setFormErrors] = useState({
        firstname: '',
        mobile: '',
        location: '',
        companyname: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        let valid = true;
        const newFormErrors = { ...formErrors };

        // Validate firstname
        if (!formData.firstname.trim()) {
            newFormErrors.firstname = 'Name is required';
            valid = false;
        } else {
            newFormErrors.firstname = '';
        }

        // Validate companyname
        if (!formData.companyname.trim()) {
            newFormErrors.companyname = 'Company Name is required';
            valid = false;
        } else {
            newFormErrors.companyname = '';
        }

        // Validate mobile
        if (!/^\d{10}$/.test(formData.mobile.trim())) {
            newFormErrors.mobile = 'Mobile number must be 10 digits';
            valid = false;
        } else {
            newFormErrors.mobile = '';
        }

        // Validate location
        if (!formData.location.trim()) {
            newFormErrors.location = 'Location is required';
            valid = false;
        } else {
            newFormErrors.location = '';
        }

        setFormErrors(newFormErrors);
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            await axios.post('https://sheet.best/api/sheets/4a9346f2-935d-4fa1-be5c-c6d0c640355f', formData);
            setShow(true);
            alert('Form submitted successfully!');

            // Clear the form fields after successful submission
            setFormData({
                firstname: '',
                mobile: '',
                location: '',
                companyname: '',
            });
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };
    const handleVideoClose = () => {
        setShowVideo(false);
    };
    return (

        <div>
            <div className="logo" ><img src={logo} alt="logob" className='logob' />
            </div>
            <div className="SuperMainClass">
                <div className="Mainclass">
                    <h2 className='groth'>Supercharge Your Business Growth with the Power of</h2>
                    <div className="outerclass">
                        <div className="animation"><h1>BotWALA Whatsapp API</h1></div>
                    </div>

                    <p className='Official'>
                        Tap into the capabilities of WhatsApp using our Official API and revolutionize your business communication.
                        Leave behind number blocks and give it a try for free today!
                    </p>

                    <div className="SubscriptionInfo">
                        <div className="sub1"><FaCheckCircle className='purches' /> Unlocking 10X growth potential through WhatsApp conversations</div>
                    </div>

                    <div className="JoinDemo">
                        <Link to="/getapi" ><Button variant="primary" className='demobtn' >
                            Schedule a Demo
                        </Button></Link>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Apply For Whatsapp API Today!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {showVideo ? (
                                    <span>
                                        <video width="640" height="360" controls>
                                            <source src="your-video.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <Button variant="link" onClick={handleVideoClose}>
                                            Close Video
                                        </Button>
                                    </span>
                                ) : (
                                    <Form autoComplete="off" onSubmit={handleSubmit}>
                                        <Form.Group controlId="formMobile">
                                            <Form.Control
                                                type="text"
                                                name="firstname"
                                                placeholder="Enter your name *"
                                                value={formData.firstname}
                                                onChange={handleInputChange}
                                                isInvalid={!!formErrors.firstname}
                                            />
                                            <Form.Control.Feedback type="invalid">{formErrors.firstname}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="formMobile">
                                            <Form.Control
                                                type="number"
                                                name="mobile"
                                                placeholder="Enter your mobile number *"
                                                value={formData.mobile}
                                                onChange={handleInputChange}
                                                isInvalid={!!formErrors.mobile}
                                            />
                                            <Form.Control.Feedback type="invalid">{formErrors.mobile}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="formMobile">
                                            <Form.Control
                                                type="text"
                                                name="location"
                                                placeholder="Enter your location *"
                                                value={formData.location}
                                                onChange={handleInputChange}
                                                isInvalid={!!formErrors.location}
                                            />
                                            <Form.Control.Feedback type="invalid">{formErrors.location}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="formMobile">
                                            <Form.Control
                                                type="text"
                                                name="companyname"
                                                placeholder="Enter your company name *"
                                                value={formData.companyname}
                                                onChange={handleInputChange}
                                                isInvalid={!!formErrors.companyname}
                                            />
                                            <Form.Control.Feedback type="invalid">{formErrors.companyname}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Button
                                            variant="primary"
                                            className='applybtn'
                                            type="submit"
                                            onClick={() => setShowVideo(true)}
                                        >
                                            Apply Now
                                        </Button>
                                    </Form>
                                )}
                            </Modal.Body>
                        </Modal>
                        <span>Play Now</span>
                        <div className="lines"><div className="greenLine"></div></div>
                        <div>

                            <button onClick={() => setShowVideo(!showVideo)} className='video'><FaPlay className='plybtn' /></button>
                            {showVideo && (
                                <Modal className='modal-body2' show={showVideo} onHide={handleVideoClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Botwala Intro</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <video  width="100%"  height="60%" controls className='bt-video'>
                                            <source src={require('./Images/intro.mp4')}  type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Modal.Body>
                                </Modal>
                            )}
                        </div>


                        {/* <div className="Video"><a href="https://youtube.com/shorts/kQRaaadywjg?si=2HwcCVhu891GoXXa"><FaPlay className='plybtn' /></a></div> */}
                    </div>
                </div>




                <div className="Images">
                    <img src={banner} alt="wt" className="wtssrc" />
                </div>

            </div>


            <div className="integration">
                <h1>
                    Seamless Integrations
                </h1>

                <p>
                    Easily Connect Your Favorite E-Stores, CRMs, And Other Tools
                </p>
            </div>

            <div className="wapi">




                <img src={img1} alt="" className="ig" />
                <img src={img2} alt="" className="ig" />
                <img src={img3} alt="" className="ig" />
                <img src={img4} alt="" className="ig" />
                <img src={img6} alt="" className="ig" />
                <img src={img7} alt="" className="ig" />
                {/* <img src={img7} alt="" className="src" /> 
                <img src={img7} alt="" className="src" /> 
                <img src={img7} alt="" className="src" />  */}
            </div>

            <div className='budget' id='PricingId'>
                <h1>Budget-Friendly</h1>
            </div>
            <div className="botwala"  >

                <div className="custom-bot">
                    <div className="hub">
                        <p>STANDARD</p>
                    </div>
                    <div className="platform">
                        <div className="logo-create">
                            <div className="logobox1">

                                <div className='Unlimited1 '>
                                    <AiOutlineMessage className='messagebox' />
                                </div>
                                <div className="logotext1">
                                    <p><b>BOT</b>WALA</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="chats">
                        {/* <h3>Get 3000 Tasks Every Month</h3> */}
                        {/* <div className="icons">
                            <FaCheck id='ic' />
                            Unlimited Operations
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <b>10 Workflows</b>
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <b>Two-Step Workflows</b>
                        </div> */}
                        <div className="one-time">
                            <div className="time-payment">
                                <strike>Normally ₹20,000</strike>
                                <h1>₹8,999</h1>
                                <h4>Yearly Payment</h4>
                                <div className="save-charges">
                                    <b>You save:₹15,001*</b>
                                </div>
                            </div>
                        </div>

                        <div className="zapier">
                            <span>Wati & AIsensy charges</span>
                            <p>₹24,000 & ₹11,000</p>
                        </div>

                        <div className="buy-standard">
                        <a href='https://crm.botwala.com/user/login'>  <button>STANDARD</button></a>
                        </div>
                        <div className="buy-in">
                            BUY IN INR ₹8,999
                        </div>

                        <div className="line2">
                            <hr id='lines' />
                        </div>

                        <div className="iconlist">

                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Unlimited Agets Logins
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Smart Audience Segregation
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Broadcasting & Retargeting
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Template Massage API's
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Multi-Agent Live Chat
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Agent Transfer & Manager Monitoring
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                AiSensy Marketplace Integrations
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                1200 Messages/min
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Upto 10 Tags
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Upto 5 Custom Attributes
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Shopify & WooCommerce Integrations
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Dialogflow Chatbot Integration
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Shared Team Inbox
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Click-to-WhatsApp Ads Manager
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                1000 Chatbot Session's
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Basic Chatbot
                            </div>


                        </div>   </div>
                </div>




                <div className="custom-bot" >
                    <div className="hub">
                        <p>PRO</p>
                    </div>
                    <div className="platform">
                        <div className="logo-create">
                            <div className="logobox1">

                                <div className='Unlimited1 '>
                                    <AiOutlineMessage className='messagebox' />
                                </div>
                                <div className="logotext1">
                                    <p><b>BOT</b>WALA</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="chats1">
                        {/* <h3>Get 6000 Tasks Every Month</h3> */}
                    </div>
                    {/* <div className="icons">
                            <FaCheck id='ic' />
                            Unlimited Operations
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <b>20 Workflows</b>
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <b>Two-Step Workflows</b>
                        </div> */}
                    <div className="one-time">
                        <div className="time-payment">
                            <strike>Normally ₹50,000</strike>
                            <h1>₹15,000</h1>
                            <h4>Yearly Payment</h4>
                            <div className="save-charges">
                                <b>You save:₹39,000*</b>
                            </div>
                        </div>
                    </div>

                    <div className="zapier">
                        <span>Wati & AIsensy charges</span>
                        <p>₹53,000 & ₹25,000</p>
                    </div>

                    <div className="buy-standard">
                       
                    <a href='https://crm.botwala.com/user/login'>  <button>BUY PRO</button></a>
                    </div>
                    <div className="buy-in">
                        BUY IN INR ₹15,000
                    </div>

                    <div className="line2">
                        <hr id='lines' />
                    </div>

                    <div className="iconlist">
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            All features in Basic Plan
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Upto 100 Tags
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Upto 20 Custom Attributes
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Campaign Scheduler
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Advanced & Interconnected Chatbots
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Campaign Click Tracking
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            2000 Chatbot Sessions
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Smart Agent Routing
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Campaign Budget Analytics
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Project APIs
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Custom Agent Rules
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            Carousel Template Click Tracking
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic1' />
                            CSV Campaign Scheduler
                        </div>
                    </div>
                </div>


                <div className="custom-bot2">
                    <div className="most">
                        <IoIosStar id='star' />
                        <IoIosStar id='star' />
                        <IoIosStar id='star' />
                        <IoIosStar id='star' />
                        <IoIosStar id='star' />
                        MOST POPULAR
                    </div>
                    <div className="hub">
                        <p style={{ color: '#2785ff' }}>ULTIMATE</p>
                    </div>
                    <div className="platform">
                        <div className="logo-create">
                            <div className="logobox1">

                                <div className='Unlimited1 '>
                                    <AiOutlineMessage className='messagebox' />
                                </div>
                                <div className="logotext1">
                                    <p><b>BOT</b>WALA</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="chats2">
                        {/* <h3>Get 10,000 Tasks Every Month</h3> */}

                    </div>
                    {/* <div className="icons">
                            <FaCheck id='ic' />
                            Unlimited Operations
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <span><u>Unlimited</u></span><b> Workflows</b>
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <span><u>Multi-Step</u></span><b> Workflows</b>
                        </div> */}
                    <div className="one-time">
                        <div className="time-payment">
                            <strike>Normally ₹1,60,000</strike>
                            <h1>Custom</h1>
                            <h4>Yearly Payment</h4>
                            <div className="save-charges">
                                <b>Custom</b>
                            </div>
                        </div>
                    </div>

                    <div className="zapier">
                        <span>Wati & AIsensy charges</span>
                        <p>Custom</p>
                    </div>

                    <div className="buy-standard  " id='buy'>
                      
                    <a href='https://crm.botwala.com/user/login'>  <button>BUY ULTIMATE</button></a>
                    </div>
                    <div className="buy-in">
                        Custom
                    </div>

                    <div className="line2">
                        <hr id='lines' />
                    </div>

                    <div className="iconlist">
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            Recommended for Brands with 5 Lac+Users
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            All features in pro plan
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            Unlimited Tags
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            Unlimited Attributes
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            Downlodable Reports
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            Dedicated Account Manager
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            Priority Customer support
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            Webhooks
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            Higher Messaging Speed
                        </div>
                        <div className="internal">
                            <MdRadioButtonChecked id='ic4' />
                            5000 Chatbot Sessions
                        </div>
                    </div>
                </div>
            </div>


            <div className='main-div' >
                <div className='threebox'>
                    <div className='box1' >
                        <span><FaStethoscope className='i1' />Wellness</span>
                    </div>
                    <div className='box2'>
                        <span><FaBook className='i1' />Learning</span>
                    </div>
                    <div className='box1'>
                        <span><IoIosPeople className='i1' />Recruitment</span>
                    </div>

                </div>
                <div className='sub-div' >
                    <div className='threebox3' id='slider'>
                        <div className='box3'>
                            <span><FaBuilding className='i1' />Property</span>
                        </div>
                        <div className='box4'>
                            <span><FaPlane className='i1' />Tourism</span>
                        </div>
                        <div className='box5'>
                            <span><IoArrowRedoSharp className='i1' />Sales</span>
                        </div>
                    </div>


                    <div className='sub-divimg'>
                        <div className='manimage'>
                            <img src={man} />
                        </div>

                    </div>

                    <div className='threebox2' id='slider1'>
                        <div className='box6'>
                            <span><FaCar className='i1' />Motorcar</span>
                        </div>
                        <div className='box7'>
                            <span><FaHeart className='i1' />Policy</span>
                        </div>
                        <div className='box8'>
                            <span><MdTrolley className='i1' />Web-Commerce</span>
                        </div>
                    </div>

                </div>
            </div>









            <div className='scroll-image'>
                <b className='trust'>Trusted by the <span className='fast'> fastest growing brands </span> in rapidly developing economies</b>
                <div className='sr-image'>
                    <div className='marquee-wrapper'>
                        <div className='marquee-container'>
                            <div className="ImageGenerater">
                                <p className='img1'> <img src={LicImg} alt="" className="LICImg" />MR Associates </p>

                                <p className='img2'><img src={SaiFinance} alt="" className="imgs1 img1" /></p>

                                <p className='img2'><img src={Fizito} alt="" className="img1" /></p>

                                <p className='img2'><img src={pacematic} alt="" className="img1" /></p>

                                <p className='img1'> <img src={pacematicT} alt="" className="LICImg" />Pacematic Technology</p>

                                <p className='img2'><img src={healandcarec} alt="" className="img1" /></p>

                                <p className='img2'><img src={smart} alt="" className="img1" /></p>

                                <p className='img1'> <img src={LicImg} alt="" className="LICImg" />MR Associates </p>

                                <p className='img2'><img src={SaiFinance} alt="" className="imgs1 img1" /></p>

                                <p className='img2'><img src={Fizito} alt="" className="img1" /></p>

                                <p className='img2'><img src={pacematic} alt="" className="img1" /></p>

                                <p className='img1 pace'> <img src={pacematicT} alt="" className="LICImg" />Pacematic Technology</p>

                                <p className='img2'><img src={healandcarec} alt="" className="img1" /></p>

                                <p className='img2'><img src={smart} alt="" className="img1" /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className='solution-page' id='CustomBot'>
                <div className='headingcase'>

                    <h1>Industry Case Studies</h1></div>

                <div className='solution-mainbox'>

                    <div className='solution-content-boxes'>
                        <div className='solution-subbox'>
                            <div className='solution-logo'>
                                <FaHandHoldingMedical className='health-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Healthcare Arena</span>
                                <p>Implementing end-to-end encryption ensures a highly secure environment for exchanging patient information, fostering enhanced confidentiality and privacy in healthcare communications."</p>
                            </div>
                        </div>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated'>
                            <div className='solution-logo'>
                                <GiSpellBook className='book-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Education Sector</span>
                                <p>Facilitate market classes, streamline admissions, seamlessly onboard students, and stay up-to-date to support elevated education standards through effective communication.</p>
                            </div>
                        </div>
                    </div>
                    <div className='solution-content-boxes'>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated'>
                            <div className='solution-logo'>
                                <RiAdvertisementLine className='ad-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Advertising Agencies</span>
                                <p>Enhance your client's advertising strategy by utilizing mobile platforms to achieve efficient lead generation and foster robust relationship building.</p>
                            </div>
                        </div>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated'>
                            <div className='solution-logo'>
                                <RiCustomerService2Fill className='client-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Client Assistance</span>
                                <p>Boost responsiveness and fortify your reputation for excellent client assistance. Address customer queries that demand immediate attention to ensure swift and effective support.</p>
                            </div>
                        </div>
                    </div>
                    <div className='solution-content-boxes'>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated' id='tourism-box '>
                            <div className='solution-logo'>
                                <GiCommercialAirplane className='plane-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Tourism Sector</span>
                                <p> Simplify communication for guests by allowing them to speak in their local language. Elevate your customers' experience and streamline their travels</p>
                            </div>
                        </div>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated'>
                            <div className='solution-logo'>
                                <FaSackDollar className='doller-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Sales Squad</span>
                                <p> Engaging in instant conversations with customers accelerates relationship building, preventing lead leakage and lost sales opportunities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Wati />
            <Partnership />
            {/* <FAQs/> */}
            {/* <Footer /> */}



























        </div>
    )
}

export default Homepage




