import React from 'react'
import '../components/TermsConditions.css'
import { ImCheckmark } from "react-icons/im";

const TermConditions = () => {
    return (
        <div>
            <div className='term-condition-page'>
           <div className='term-head'>
           <h1>Terms & Conditions</h1>
           </div>
                <div className='term-condition-box'>
                   
                    <div className='terms-content'>
                        <p>Under the banner of BotWala ("BotWala", "we," "us," or "our"), our Software as a Service (SaaS) 'Conversation Platform,' referred to as 'BotWala Platform' or 'BotWala Whatsapp API Chatbot Solution,' empowers customers to seamlessly store, manipulate, analyze, and transfer messages among their business systems. A 'Customer' denotes an entity with whom BotWala has a service agreement.</p>
                        <div className='terms-items'>
                            <h5>Regarding The Conditions</h5>
                            <p>The Agreement, herein referred to as the Terms of Service, constitutes a binding contract between the Customer and BotWala. Both entities, collectively termed as the "Parties" and individually as a "Party," enter into this Agreement on the date the Customer enrolls for a BotWala account, whether through the BotWala website, the Executive Team, or as specified in the Service Agreement—recognized as the "Effective Date."</p>
                            <p>By registering for a BotWala account, you affirm your understanding of these terms and instigate the Agreement on behalf of the Customer. Prior to proceeding, please confirm that you have the necessary authority to enter into the Agreement on behalf of the Customer.</p>
                            <p>BotWala retains the authority to periodically update and modify the Terms of Service without prior notice. The incorporation of new features enhancing the existing Service, including the introduction of new tools and resources, falls under the governance of the Terms of Service. Your ongoing utilization of the Service after such modifications will indicate your acceptance of the revised terms.</p>
                            <p>An individual or legal entity is restricted from maintaining more than one account.</p>
                        </div>
                    </div>
                    
                    <div className='terms-items'>
                        <h5> Account</h5>
                        <p><ImCheckmark  className='terms-check'/> You must provide a valid email address and any other requested information to successfully complete the signup process with Botwala.</p>
                        <p><ImCheckmark  className='terms-check'/> The security of your account and password is your responsibility.</p>
                        <p><ImCheckmark  className='terms-check'/> BotWala is not responsible and will not be held accountable for any loss or damage arising from your failure to fulfill this security obligation.</p>
                    </div>
                    <div className='terms-items'>
                        <h5> Approval</h5>
                        <p><ImCheckmark  className='terms-check'/> Registering with BotWala grants us permission to send you emails, encompassing but not limited to marketing, promotional, and communications pertaining to BotWala.</p>
                    </div>
                    <div className='terms-items'>
                        <h5>Restriction</h5>
                        <p>When using any BotWala content and/or the BotWala Platform, unless otherwise approved in writing by BotWala, the following prohibitions apply:</p>


                        <p><ImCheckmark  className='terms-check'/> You will not use the APIs or other BotWala content on behalf of any third party, unless such third party has expressly authorized you to access and use the BotWala content under its account.</p>
                        <p><ImCheckmark  className='terms-check'/> It is prohibited to duplicate, rent, lease, sell, transfer, assign, sublicense, disassemble, reverse engineer, or decompile (except to the limited extent expressly authorized by applicable statutory law), modify, or alter any component of the Website(s), APIs, other BotWala content, or the BotWala Platform.</p>
                        <p><ImCheckmark  className='terms-check'/> You shall refrain from utilizing the APIs, other BotWala content, or the BotWala Platform in association with inherently dangerous objects, devices, materials, or situations, or engaging in activities likely to cause harm to any individual or property.</p>
                        <p><ImCheckmark  className='terms-check'/> You are prohibited from utilizing the BotWala content or BotWala Platform, or exercising control over any customer accounts, products, devices, or applications in a way that could inflict harm, damage, or loss upon any individual. Moreover, engaging in activities that may harm, defame, abuse, harass, stalk, or threaten others is strictly forbidden. Additionally, you must not permit or encourage any third party to partake in such actions.</p>
                        <p><ImCheckmark  className='terms-check'/> You shall not undertake any action with the intent of introducing viruses, worms, defects, Trojan horses, malware, or any items of a destructive nature to the Website(s), BotWala content, or BotWala Platform, as well as the applications or products of any third party.</p>
                        <p><ImCheckmark  className='terms-check'/> The use of BotWala content or BotWala Platform for providing adult entertainment-oriented or pornographic services is strictly prohibited.</p>
                        <p><ImCheckmark  className='terms-check'/> You will not develop an application that operates substantially the same as the APIs or other BotWala content and make it available for use by third parties.</p>
                    </div>
                    <div className='terms-items'>
                        <h5>Compliance</h5>
                        <p><ImCheckmark  className='terms-check'/> We reserve the right to modify or update the Website(s), BotWala content, and/or BotWala Platform at any time, for any reason, and without notice to you. If BotWala makes updates, revisions, breaking changes, or in any way modifies an API or other BotWala content, you agree that you are solely responsible for making changes to your Device or Application to ensure continued service for your End Users. We are constantly changing and improving our APIs and other BotWala content. We may add or remove functionalities or features at our discretion, for any reason, and we do not guarantee that your Device or Application will function with any future or modified versions of any BotWala content or the BotWala Platform.</p>
                    </div>
                    <div className='terms-items'>
                        <h5>
                            Recognition</h5>
                        <h6>Label</h6>
                        <p><ImCheckmark  className='terms-check'/> Unless explicitly stated otherwise, these Terms do not confer either party any right, title, or interest in the trade names, trademarks, service marks, logos, domain names, and other distinctive brand features (collectively referred to as "Marks") of the other party.</p>
                        <h6>Promotion</h6>
                        <p><ImCheckmark  className='terms-check'/> You are prohibited from making any statement regarding your use of an API that implies a partnership with, sponsorship by, or endorsement by BotWala without obtaining prior written approval from BotWala.</p>

                        <h6>Promotional and Marketing Utilization</h6>
                        <p><ImCheckmark  className='terms-check'/> During the promotion, marketing, or demonstration of the APIs you utilize, BotWala may generate and distribute incidental depictions, such as screenshots or other content from your Application or Device. Additionally, BotWala may use your company or product name and logos for these purposes. You hereby grant BotWala all necessary rights for these activities.</p> </div>
                
                <div className='terms-items'>
                    <h5>
                        Dispute Resolution</h5>
                    <p><ImCheckmark  className='terms-check'/> User can address any grievances related to the Account to the grievance officer of BotWala, who can be contacted at Support@botwala.com.</p>
                </div>
                </div>


            </div>

        </div>
    )
}

export default TermConditions
