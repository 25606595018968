
import './Homepage.css'
import React, { useRef } from 'react'
import './Homepage.css'
import img1 from './Images/1.jpg'
import img2 from './Images/2.jpg'
import img3 from './Images/3.jpg'
import img4 from './Images/4.jpg'
import img6 from './Images/6.jpg'
import img7 from './Images/7.jpg'
import img5 from './Images/5.jpg'
import { FaCheck } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosStar } from "react-icons/io";
import { AiOutlineMessage } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import logo from './Images/b2.png';
import banner from './Images/banner1.jpg';
import srl1 from './Images/three.svg';
import srl2 from './Images/Changi-airport.svg';
import srl3 from './Images/Emami.svg';
import srl4 from './Images/ITC.svg';
import srl5 from './Images/Killer.svg';
import srl6 from './Images/Oxwhitee.svg';
import srl7 from './Images/Pillup.svg';
import srl8 from './Images/Welspun-logo.svg';
import srl9 from './Images/Za-insure-logo.svg';
import srl10 from './Images/Zoomar.svg';
import srl11 from './Images/edureka.svg';
import srl12 from './Images/f-bank.svg';
import srl13 from './Images/jp.svg';
import srl14 from './Images/mivi.svg';
import srl15 from './Images/swine.svg';
import srl16 from './Images/tiktok.svg';
import srl17 from './Images/weland.svg';
import srl18 from './Images/f-bank.svg';
import Navbar from './Navbar'
import { FaHandHoldingMedical } from "react-icons/fa";
import { GiSpellBook } from "react-icons/gi";
import { RiAdvertisementLine } from "react-icons/ri";
import { FaUsersViewfinder } from "react-icons/fa6";
import { RiCustomerService2Fill } from "react-icons/ri";
import { GiCommercialAirplane } from "react-icons/gi";
import { FaSackDollar } from "react-icons/fa6";
// import wplogo from './Images/watsapp apk.png'
import { IoIosCheckbox } from "react-icons/io";
import LicImg from './Images/LIC img.png'
import SaiFinance from './Images/partnershipdeal-removebg-preview.png'
import Fizito from './Images/Fizito img.png'
import pacematic from './Images/paceTC.png'
import pacematicT from './Images/pacematic tech.jpeg'
import healandcarec from './Images/Heal and Care imge.png'
import smart from './Images/saiMart2.png'
import man from './Images/manb.png';
import { FaBook } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import { FaCar } from "react-icons/fa6";
import { FaHeart } from "react-icons/fa";
import { FaPlane } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa";
import { IoArrowRedoSharp } from "react-icons/io5";
import { MdTrolley } from "react-icons/md";
import { FaStethoscope } from "react-icons/fa6";

const CaseStudies = () => {
  return (
    <div>
       <div className='solution-page' id='CustomBot'>
                <div className='headingcase'>

                    <h1>Industry Case Studies</h1></div>

                <div className='solution-mainbox'>

                    <div className='solution-content-boxes'>
                        <div className='solution-subbox'>
                            <div className='solution-logo'>
                                <FaHandHoldingMedical className='health-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Healthcare Arena</span>
                                <p>Implementing end-to-end encryption ensures a highly secure environment for exchanging patient information, fostering enhanced confidentiality and privacy in healthcare communications."</p>
                            </div>
                        </div>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated'>
                            <div className='solution-logo'>
                                <GiSpellBook className='book-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Education Sector</span>
                                <p>Facilitate market classes, streamline admissions, seamlessly onboard students, and stay up-to-date to support elevated education standards through effective communication.</p>
                            </div>
                        </div>
                    </div>
                    <div className='solution-content-boxes'>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated'>
                            <div className='solution-logo'>
                                <RiAdvertisementLine className='ad-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Advertising Agencies</span>
                                <p>Enhance your client's advertising strategy by utilizing mobile platforms to achieve efficient lead generation and foster robust relationship building.</p>
                            </div>
                        </div>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated'>
                            <div className='solution-logo'>
                                <RiCustomerService2Fill className='client-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Client Assistance</span>
                                <p>Boost responsiveness and fortify your reputation for excellent client assistance. Address customer queries that demand immediate attention to ensure swift and effective support.</p>
                            </div>
                        </div>
                    </div>
                    <div className='solution-content-boxes'>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated' id='tourism-box '>
                            <div className='solution-logo'>
                                <GiCommercialAirplane className='plane-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Tourism Sector</span>
                                <p> Simplify communication for guests by allowing them to speak in their local language. Elevate your customers' experience and streamline their travels</p>
                            </div>
                        </div>
                        {/* Add the 'animated' class to the solution-subbox */}
                        <div className='solution-subbox animated'>
                            <div className='solution-logo'>
                                <FaSackDollar className='doller-sl' />
                            </div>
                            <div className='solution-info'>
                                <span>Sales Squad</span>
                                <p> Engaging in instant conversations with customers accelerates relationship building, preventing lead leakage and lost sales opportunities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default CaseStudies
