import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Form.css';

const Getform = () => {
    // Form states
    const [names, setNames] = useState('');
    const [mobiles, setMobiles] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [data, setData] = useState([]);

    // Error states
    const [nameError, setNameError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [companyError, setCompanyError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [addressError, setAddressError] = useState('');

    // Submit event
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        let valid = true;

        if (!names) {
            setNameError('Name is required');
            valid = false;
        } else {
            setNameError('');
        }

        if (!mobiles || !/^\d{10}$/.test(mobiles)) {
            setMobileError('Mobile number must be 10 digits');
            valid = false;
        } else {
            setMobileError('');
        }

        if (!company) {
            setCompanyError('Company Name is required');
            valid = false;
        } else {
            setCompanyError('');
        }

        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setEmailError('Valid email address is required');
            valid = false;
        } else {
            setEmailError('');
        }

        if (!address) {
            setAddressError('Description is required');
            valid = false;
        } else {
            setAddressError('');
        }

        if (!valid) {
            return;
        }

        // Object to pass
        const formData = {
            names,
            company,
            mobiles,
            address,
            email,
        };

        try {
            // Post data to the Google Sheet
            await axios.post('https://sheet.best/api/sheets/ed8e6b80-e815-4e5a-8595-eab0b7ea6e53', formData);

            // Clear form fields after successful submission
            setNames('');
            setMobiles('');
            setCompany('');
            setAddress('');
            setEmail('');

            // Fetch updated data after submission
            getData();
            setSubmitted(true);  // Set submitted state to true

            // Show alert message
            alert('Your message has been received!');
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    // Get data from the Google Sheet
    const getData = async () => {
        try {
            const response = await axios.get('https://sheet.best/api/sheets/ed8e6b80-e815-4e5a-8595-eab0b7ea6e53');
            setData(response.data);
        } catch (error) {
            console.error('Error getting data:', error);
        }
    };

    // Trigger getData function on component mount
    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <div className="partner-form">
                <div className="partner-growth">
                    <h1>PARTNER</h1>
                    <hr className='formline' />
                    <form autoComplete="off" className='form-group' id='form-content-1' onSubmit={handleSubmit}>

                        <div className="input-detail">
                            <div className="form-data1">
                              
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        onChange={(e) => setNames(e.target.value)}
                                        value={names}
                                        placeholder="Name"
                                    />
                                    <span className='error-message'>{nameError}</span>
                                
                                
                                    <input
                                        type="number"
                                        id="mobile"
                                        name="mobile"
                                        onChange={(e) => setMobiles(e.target.value)}
                                        value={mobiles}
                                        placeholder="Mobile No"
                                    />
                                    <span className='error-message'>{mobileError}</span>
                                </div>
                          

                            <div className="form-data1">
                               
                                    <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        onChange={(e) => setCompany(e.target.value)}
                                        value={company}
                                        placeholder="Company Name"
                                    />
                                    <span className='error-message'>{companyError}</span>
                              
                                
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        placeholder="Email"
                                    />
                                    <span className='error-message'>{emailError}</span>
                                </div>
                           
                            <div className="address-bar">
                                
                                    <textarea
                                        name="address"
                                        id="address"
                                        onChange={(e) => setAddress(e.target.value)}
                                        value={address}
                                        cols="135"
                                        rows="6"
                                        placeholder="Description"
                                    ></textarea>
                                    <span className='error-message'>{addressError}</span>
                                </div>
                           

                            <div className="sub-button">
                                <button type="submit" className="next-btn">
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Getform;
