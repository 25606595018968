import React, { useRef, useState, useEffect } from 'react'
import '../components/GetPage.css'
import { LuDot } from "react-icons/lu";
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import rocket from './Images/airo.jpg'
import kickscooty from './Images/cycle.jpg'
import whatimg from './Images/banner1.jpg';
import { RxDot } from "react-icons/rx";
import botimg from '../components/Images/updated img.png'
import { Link } from 'react-router-dom';


const GetPage = () => {
   

    return (
        <>  

            <div className='main-class'>


                <div className='mainsection'>

                     <div className='sub-section2'>
                        <img src={botimg} alt="" className="whatapi" />

                    </div>
                    <div className='sub-section1'>
                        <div className='time-headeing'>
                            <h2>Time to unleash engagement on <span>WhatsApp.</span></h2>
                        </div>
                        <div className='facingtext'>
                            <span>
                                Are you experiencing any of these issues?



                            </span>
                        </div>
                        <div className='Email-text'>
                            <p> <LuDot className='dot' />
                                No outcomes from email or SMS marketing efforts</p>
                            <p> <LuDot className='dot' />
                                Unable to mass broadcast to 1000+ WhatsApp users.
                            </p>
                            <p> <LuDot className='dot' />Blocked for utilizing unauthorized bulk WhatsApp software</p>
                        </div>

                        <div className='free-text'>
                            <p>
                                Apply for the<span> WhatsApp Business API </span> with <span>Botwala</span> at no cost👇 </p>
                        </div> 
                    
                    <div className='start-btn'>
                        <Link to='/getapi' className='gets'><button>
                            Start your 15-Day Free for Trial</button></Link>
                    </div>
                </div>
                </div>




                <div className='apiheading'>
                    <h2>Grow your business with WhatsApp Business API</h2>
                    <div className='message-text'>
                        <div className="dot-text">
                            <RxDot id='dot' />

                            <span>WhatsApp's sky-high <b>98%</b>message open rate, coupled with click-through rates ranging from <b>45% to 80%,</b> solidifies its position as the ultimate customer engagement platform.</span>
                        </div>
                        <div className="dot-text">
                            <RxDot id='dot' />
                            <span>Meta's <b>WhatsApp Business API</b> enables businesses to unlock the full potential  of WhatsApp Marketing, driving enhanced customer engagement and interaction.</span>
                        </div>
                        <div className="dot-text">
                            <RxDot id='dot' />
                            <span><b>WhatsApp Business API,</b> launched by Meta, enables businesses to scale communication with leads and customers effectively on the platform.</span>
                        </div>

                        <div className="dot-text">
                            <RxDot id='dot' />
                            <span>The WhatsApp Business API offers the<b>official </b>solution for scaling user engagement and business growth on the platform.</span>
                        </div>
                        <div className="dot-text">
                            <RxDot id='dot' />
                            <p>Rest assured, your numbers remain unblocked with ease.</p>
                        </div>
                        <div className="dot-text">
                            <RxDot id='dot' />
                            <span>Empower your business with <b>Botwala's WhatsApp API platform,</b> the vast potential of WhatsApp for unparalleled growth and success.</span>
                        </div>
                        <div className="dot-text">
                            <RxDot id='dot' />

                            <span>Experience the <b>WhatsApp API for free</b> today!</span>
                        </div>
                    </div>

                </div>


                <div className='api-buisness'>
                    <h2>What makes WhatsApp Business<br /> API unique?





                    </h2>

                    <div className='api-smalltext'>
                        <span>WhatsApp Business API offers advanced features for scalability.</span>
                    </div>

                    <div className='api-box'>
                        <div className='three-boxe'>
                            <div className='api-boxs'>
                                <div className='t0'>
                                    <span>Broadcasting Limit</span>
                                </div>

                                <div className='t0'>
                                    <span>Multi-User Access</span>
                                </div>

                                <div className='t0'>
                                    <span>Install Chatbots</span>
                                </div>

                                <div className='t0'>
                                    <span>Automated Messaging</span>
                                </div>
                                <div className='t0'>
                                    <span>Broadcast Reaches</span>
                                </div>
                                <div className='t0'>
                                    <span>Coding Required </span>
                                </div>
                                <div className='t0'>
                                    <span>Verified Green Tick </span>
                                </div>
                                <div className='t0'>
                                    <span>Clickable Messages</span>
                                </div>
                                <div className='t0'>
                                    <span>Dashboard</span>
                                </div>
                            </div>
                            <div className='api-box1'>
                                <div className="rocket-img">
                                    <img src={rocket} alt="" className="rocket" />
                                </div>
                                <div className="whats-business">
                                    <h3>Growing Businesses</h3>
                                    <p>Whatsapp Business API</p>
                                </div>
                                <div className='three-boxes'>
                                    <div className='t1'>
                                        <FaCheckCircle className='ri' /><span>UNLIMITED Users/ Day</span>
                                    </div>

                                    <div className='t1'>
                                        <FaCheckCircle className='ri' /><span>Unlimited Users</span>
                                    </div>
                                    <div className='t1'>
                                        <FaCheckCircle className='ri' /><span>Yes</span>
                                    </div>
                                    <div className='t1'>
                                        <FaCheckCircle className='ri' /><span>Yes</span>
                                    </div>
                                    <div className='t1'>
                                        <FaCheckCircle className='ri' /><span>All Users</span>
                                    </div>
                                    <div className='t1'>
                                        <FaTimesCircle className='cr1' /><span>Yes, But Not Required if you use Botwala</span>
                                    </div>
                                    <div className='t1'>
                                        <FaCheckCircle className='ri' /><span>Yes</span>
                                    </div>
                                    <div className='t1'>
                                        <FaCheckCircle className='ri' /><span>Yes</span>
                                    </div>
                                    <div className='t1'>
                                        <FaCheckCircle className='ri' /><span>Yes</span>
                                    </div>

                                </div>


                            </div>
                            <div className='api-box1'>
                                <div className="kick">
                                    <img src={kickscooty} alt="" className="rocket" />
                                </div>
                                <div className="whats-business">
                                    <h3>Small Businesses</h3>
                                    <p>Whatsapp Business App</p>
                                </div>
                                <div className='three-boxes'>
                                    <div className='t2'>
                                        <FaTimesCircle className='cr' /><span>256 Contacts</span>
                                    </div>

                                    <div className='t2'>
                                        <FaTimesCircle className='cr' /><span>No</span>
                                    </div>
                                    <div className='t2'>
                                        <FaTimesCircle className='cr' /><span>Not Possible</span>
                                    </div>
                                    <div className='t2'>
                                        <FaTimesCircle className='cr' /><span>Not Possible</span>
                                    </div>
                                    <div className='t2'>
                                        <FaTimesCircle className='cr2' /><span>Only who have saved your number</span>
                                    </div>
                                    <div className='t2'>
                                        <FaCheckCircle className='ri1' /><span>No, As App given by Whatsapp</span>
                                    </div>
                                    <div className='t2'>
                                        <FaTimesCircle className='cr' /><span>Not Possible</span>
                                    </div>
                                    <div className='t2'>
                                        <FaTimesCircle className='cr' /><span>No</span>
                                    </div>
                                    <div className='t2'>
                                        <FaTimesCircle className='cr' /><span>No</span>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div className='apply-btn'>
                           <Link to='/form' > <button>Apply For Free</button></Link>
                        </div>



                    </div>


                </div>

            </div>

        </>
    )
}

export default GetPage