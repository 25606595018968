import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineMessage } from "react-icons/ai";
import './Login.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [data, setData] = useState([]);
    const [loginError, setLoginError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        if (!username || !password) {
            setLoginError('Please fill in both username and password.');
            return;
        }

        const formData = {
            username,
            password,
        };

        // Check if the provided username and password match any user in the sheet
        const userFound = data.find(user => user.username === formData.username && user.password === formData.password);

        if (userFound) {
            setLoginError('');
            // Clear form fields after successful submission
            setUsername('');
            setPassword('');
            setSubmitted(true);

            // Redirect to the "/dashboard" page upon successful login
            navigate('/dashboard');
        } else {
            setLoginError('Invalid username or password. Please try again.');
        }
    };

    useEffect(() => {
        // Fetch data on component mount
        const fetchData = async () => {
            try {
                const response = await axios.get('https://sheet.best/api/sheets/55b20f79-9450-4f9a-94e1-9aa46e797b7d');
                setData(response.data);
            } catch (error) {
                console.error('Error getting data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="login-main">
                <div className="login1 lg1">
                    <div className="company-logo">
                        <Link to="/" >
                            <div className="platform plat">
                                <div className="logo-create lc">
                                    <div className="logobox1 lg-box1">
                                        <div className='Unlimited1 un '>
                                            <AiOutlineMessage className='messagebox mg' />
                                        </div>
                                        <div className="logotext1 lgt">
                                            <p className="lgtp"><b>BOT</b>WALA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <h1>Login</h1>
                    <form autoComplete="off" className='form-group fg' id='form-content-1' onSubmit={handleSubmit}>
                        <input
                            type="text"
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                            placeholder="Username"
                            className="username"
                        />
                        <input
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            placeholder="Password"
                            className="username"
                        />
                        <div className="sub-btn">
                            <button type="submit" className="login-btn">
                                Login
                            </button>
                        </div>
                    </form>
                    {loginError && <p className="error-messages">{loginError}</p>}
                    <br />
                    <div className="upper">
                        <p>Don't have an account?</p>
                        <Link to="/signup">SignUp here</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
