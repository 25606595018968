import React from 'react'
import "./footer.css"
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { PiYoutubeLogoFill } from "react-icons/pi";
import { FaLinkedin } from "react-icons/fa6";
import f1 from "./Images/f1.png"
import f2 from "./Images/tw1.png"
import f3 from "./Images/y1.png"
import f4 from "./Images/lin1.png"
import f5 from "./Images/wi1.png"
import f6 from "./Images/ins1.png"
import { Link } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";

const Footer = () => {
  return (
    <>
      <div className='footer-design'>


        <div className='ft-pages'>
          <div className='copyright'>

            <div className='ft-page'>
              <Link to="/privacy" className='ft1'>Privacy Policy</Link>
              <Link to="/terms" className='ft1'> Terms & Conditions</Link>
              <Link to="/refund" className='ft1'>Refund Policy</Link>

              <Link to="/contact" className='ft1'> Contact Us</Link>            </div>
          </div>
        </div>
        <hr className='ft-hr'/>
        <div className='mainfooter'>
          <div className='misscall'>
            <p>Give a Miss Call for Free Trial:<span>+91-9699611503</span></p>
          </div>


          <div className='followus'>
            <div className='logoicon'>
              <div className='followfooter'> <span>Follow Us On:</span>
              </div>
              <div>
                <a href='https://www.facebook.com/BotwalaPM'> <img className='f1' src={f1}></img></a>
                <a href='https://www.instagram.com/botwala_WABA'><img className='f5' src={f6}></img>
</a>
                {/* <img className='f1' src={f2}></img>
                <img className='f2' src={f3}></img>
                <img className='f3' src={f4}></img>
                <img className='f4' src={f5}></img> */}
               
              </div>




            </div>


          </div>



        </div>
        <div className='copyr'>
          <span>@2024 Botwala Powered by Pacematic Digital Marketing Solutions</span>
        </div>

      </div>


    </>
  )
}

export default Footer