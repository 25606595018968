import { useEffect } from "react";
import { useLocation } from "react-router-dom";



import React, { useState } from 'react';

const SmoothScrollComponent = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (event) => {
    setScrollPosition(event.target.scrollTop);
  };

  return (
    <div
      className="scrolling-container"
      onScroll={handleScroll}
    >
      {/* Your scrollable content here */}
    </div>
  );
};


export default function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect ( () => {
        window.scrollTo(0, 0);
    }, [pathname] );

    return null;

    
}