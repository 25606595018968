


import './Homepage.css'
import img1 from './Images/1.jpg'
import img2 from './Images/2.jpg'
import img3 from './Images/3.jpg'
import img4 from './Images/4.jpg'
import img6 from './Images/6.jpg'
import { MdRadioButtonChecked } from "react-icons/md"
import img7 from './Images/7.jpg'
import img5 from './Images/5.jpg'
import { FaCheck } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosStar } from "react-icons/io";
import { AiOutlineMessage } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import logo from './Images/b2.png';
import banner from './Images/banner1.jpg';
import srl1 from './Images/three.svg';
import srl2 from './Images/Changi-airport.svg';
import srl3 from './Images/Emami.svg';
import srl4 from './Images/ITC.svg';
import srl5 from './Images/Killer.svg';
import srl6 from './Images/Oxwhitee.svg';
import srl7 from './Images/Pillup.svg';
import srl8 from './Images/Welspun-logo.svg';
import srl9 from './Images/Za-insure-logo.svg';
import srl10 from './Images/Zoomar.svg';
import srl11 from './Images/edureka.svg';
import srl12 from './Images/f-bank.svg';
import srl13 from './Images/jp.svg';
import srl14 from './Images/mivi.svg';
import srl15 from './Images/swine.svg';
import srl16 from './Images/tiktok.svg';
import srl17 from './Images/weland.svg';
import srl18 from './Images/f-bank.svg';
import Navbar from './Navbar'

const Pricing = () => {
  return (
    <div>
   <div className='budget' id='PricingId'>
                <h1>Budget-Friendly</h1>
            </div>
            <div className="botwala"  >

                <div className="custom-bot">
                    <div className="hub">
                        <p>STANDARD</p>
                    </div>
                    <div className="platform">
                        <div className="logo-create">
                            <div className="logobox1">

                                <div className='Unlimited1 '>
                                    <AiOutlineMessage className='messagebox' />
                                </div>
                                <div className="logotext1">
                                    <p><b>BOT</b>WALA</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="chats">
                        <h3>Get 3000 Tasks Every Month</h3>
                        {/* <div className="icons">
                            <FaCheck id='ic' />
                            Unlimited Operations
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <b>10 Workflows</b>
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <b>Two-Step Workflows</b>
                        </div> */}
                         <div className="one-time">
                            <div className="time-payment">
                                <strike>Normally ₹20,000</strike>
                                <h1>₹8,999</h1>
                                <h4>Yearly Payment</h4>
                                <div className="save-charges">
                                    <b>You save:₹15,001*</b>
                                </div>
                            </div>
                        </div>

                        <div className="zapier">
                            <span>Wati & AIsensy charges</span>
                            <p>₹24,000 & ₹11,000</p>
                        </div>

                        <div className="buy-standard">
                        <a href='https://crm.botwala.com/user/login'>  <button>STANDARD</button></a>
                        </div>
                        <div className="buy-in">
                            BUY IN INR ₹8,999
                        </div>
                        <div className="line2">
                            <hr id='lines' />
                        </div>

                        <div className="iconlist">
                            
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Unlimited Agets Logins
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Smart Audience Segregation
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Broadcasting & Retargeting
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Template Massage API's
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Multi-Agent Live Chat
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Agent Transfer & Manager Monitoring
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    AiSensy Marketplace Integrations
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    1200 Messages/min
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Upto 10 Tags
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Upto 5 Custom Attributes
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Shopify & WooCommerce Integrations
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Dialogflow Chatbot Integration
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Shared Team Inbox
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Click-to-WhatsApp Ads Manager
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    1000 Chatbot Session's
                                </div>
                                <div className="internal">
                                    <MdRadioButtonChecked id='ic1' />
                                    Basic Chatbot
                                </div>
                           

                            </div>   </div>
                    </div>




                    <div className="custom-bot" >
                        <div className="hub">
                            <p>PRO</p>
                        </div>
                        <div className="platform">
                            <div className="logo-create">
                                <div className="logobox1">

                                    <div className='Unlimited1 '>
                                        <AiOutlineMessage className='messagebox' />
                                    </div>
                                    <div className="logotext1">
                                        <p><b>BOT</b>WALA</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="chats1">
                            <h3>Get 6000 Tasks Every Month</h3>
                        </div>
                        {/* <div className="icons">
                            <FaCheck id='ic' />
                            Unlimited Operations
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <b>20 Workflows</b>
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <b>Two-Step Workflows</b>
                        </div> */}
                        <div className="one-time">
                        <div className="time-payment">
                            <strike>Normally ₹50,000</strike>
                            <h1>₹15,000</h1>
                            <h4>Yearly Payment</h4>
                            <div className="save-charges">
                                <b>You save:₹39,000*</b>
                            </div>
                        </div>
                    </div>

                    <div className="zapier">
                        <span>Wati & AIsensy charges</span>
                        <p>₹53,000 & ₹25,000</p>
                    </div>

                    <div className="buy-standard">
                       
                    <a href='https://crm.botwala.com/user/login'>  <button>BUY PRO</button></a>
                    </div>
                    <div className="buy-in">
                        BUY IN INR ₹15,000
                    </div>

                        <div className="line2">
                            <hr id='lines' />
                        </div>

                        <div className="iconlist">
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                All features in Basic Plan
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Upto 100 Tags
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Upto 20 Custom Attributes
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Campaign Scheduler
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Advanced & Interconnected Chatbots
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Campaign Click Tracking
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                2000 Chatbot Sessions
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Smart Agent Routing
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Campaign Budget Analytics
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Project APIs
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Custom Agent Rules
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                Carousel Template Click Tracking
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic1' />
                                CSV Campaign Scheduler
                            </div>
                        </div>
                    </div>


                    <div className="custom-bot2">
                        <div className="most">
                            <IoIosStar id='star' />
                            <IoIosStar id='star' />
                            <IoIosStar id='star' />
                            <IoIosStar id='star' />
                            <IoIosStar id='star' />
                            MOST POPULAR
                        </div>
                        <div className="hub">
                            <p style={{ color: '#2785ff' }}>ULTIMATE</p>
                        </div>
                        <div className="platform">
                            <div className="logo-create">
                                <div className="logobox1">

                                    <div className='Unlimited1 '>
                                        <AiOutlineMessage className='messagebox' />
                                    </div>
                                    <div className="logotext1">
                                        <p><b>BOT</b>WALA</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="chats2">
                            <h3>Get 10,000 Tasks Every Month</h3>

                        </div>
                        {/* <div className="icons">
                            <FaCheck id='ic' />
                            Unlimited Operations
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <span><u>Unlimited</u></span><b> Workflows</b>
                        </div>
                        <div className="icons">
                            <FaCheck id='ic' />
                            <span><u>Multi-Step</u></span><b> Workflows</b>
                        </div> */}
                        <div className="one-time">
                        <div className="time-payment">
                            <strike>Normally ₹1,60,000</strike>
                            <h1>Custom</h1>
                            <h4>Yearly Payment</h4>
                            <div className="save-charges">
                                <b>Custom</b>
                            </div>
                        </div>
                    </div>

                    <div className="zapier">
                        <span>Wati & AIsensy charges</span>
                        <p>Custom</p>
                    </div>

                    <div className="buy-standard  " id='buy'>
                    
                        <a href='https://crm.botwala.com/user/login'>  <button>BUY ULTIMATE</button></a>
                    </div>
                    <div className="buy-in">
                        Custom
                    </div>
                        <div className="line2">
                            <hr id='lines' />
                        </div>

                        <div className="iconlist">
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                Recommended for Brands with 5 Lac+Users
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                All features in pro plan
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                Unlimited Tags
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                Unlimited Attributes
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                Downlodable Reports
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                Dedicated Account Manager
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                Priority Customer support
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                Webhooks
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                Higher Messaging Speed
                            </div>
                            <div className="internal">
                                <MdRadioButtonChecked id='ic4' />
                                5000 Chatbot Sessions
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
  )
}

export default Pricing
