import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css';

const Contact = () => {
    // Form states
    const [names, setNames] = useState('');
    const [email, setEmail] = useState('');
    const [product, setProduct] = useState('');
    const [queryType, setQueryType] = useState('');
    const [message, setMessage] = useState('');

    // Error states
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');

    // Form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        let valid = true;

        if (!names.trim()) {
            setNameError('Name is required *');
            valid = false;
        } else {
            setNameError('');
        }

        if (!email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setEmailError('Valid email address is required *');
            valid = false;
        } else {
            setEmailError('');
        }

        if (valid) {
            // Object to pass
            const formData = {
                names,
                email,
                product,
                queryType,
                message,
            };

            try {
                // Post data to the Google Sheet
                await axios.post('https://sheet.best/api/sheets/4a9346f2-935d-4fa1-be5c-c6d0c640355f', formData);

                // Clear form fields after successful submission
                setNames('');
                setEmail('');
                setProduct('');
                setQueryType('');
                setMessage('');

                // Show alert message
                alert('Your message has been received!');
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        }
    };

    return (
        <div className='main-contact'>
            <div className='sub-contact'>
               <div className='contact-head'>
               <h2>How Can We Help You?</h2>
               </div>
                <span>Got a question? We'd love to hear from you. Send us a message, and we’ll respond as soon as possible </span>
            </div>
            <div className='contact-box'>
                <div className='sub-contact-box'>
                    <h2>Botwala</h2>
                </div>
                <div className='contact-text'>
                    <div className='c-text'> <span>Write to us and we'll get back to you as soon as possible.</span></div>
                    <form autoComplete="off" className='form-group' id='form-content-1' onSubmit={handleSubmit}>
                        <div className='contact-inputs'>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={names}
                                onChange={(e) => setNames(e.target.value)}
                                placeholder="Name"
                            />
                            {nameError && <span className='error-message'>{nameError}</span>}
                            <input
                                type="text"
                                id="Email"
                                name="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                            />
                            {emailError && <span className='error-message'>{emailError}</span>}
                        </div>

                        <div className='contact-select'>
                            <span>Choose Your Botwala Product</span>
                            <select onChange={(e) => setProduct(e.target.value)} value={product}>
                                <option className='ob' value="select">select</option>
                                <option className='ob' value="Botwala Connect">Botwala Connect</option>
                                <option className='ob' value="Botwala Subscription Billing">Botwala Subscription Billing</option>
                                <option className='ob' value="Botwala Form Builder">Botwala Form Builder</option>
                                <option className='ob' value="Botwala Email Marketing">Botwala Email Marketing</option>
                                <option className='ob' value="Botwala Email Verification">Botwala Email Verification</option>
                                <option className='ob' value="Botwala Plus">Botwala Plus</option>
                            </select>
                            <span>Query Type</span>
                            <select onChange={(e) => setQueryType(e.target.value)} value={queryType}>
                                <option value="select">select</option>
                                <option value="Presales Query">Presales Query</option>
                                <option value="Technical Query">Technical Query</option>
                                <option value="Billing Query">Billing Query</option>
                                <option value="Delete Account">Delete Account</option>
                                <option value="Integration Partnership">Integration Partnership</option>
                            </select>
                        </div>
                        <div className='contact-textarea'>
                            <textarea
                                name="message"
                                id="message"
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                                cols="77"
                                rows="4"
                                placeholder='Your Message'
                            ></textarea>
                        </div>

                        <div className='contact-btn'>
                            <button type="submit">Submit Now</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
