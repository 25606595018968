import React,{useState} from 'react'
import "./faqs.css";
import Navbar from './Navbar';

const FAQs = () => {

    const initialFAQState = [
        { question: 'What is the WhatsApp Business Platform?', answer: ' Showcase your products and services in a mobile storefront and group similar items into collections to simplify the shopping experience for your customers.', isOpen: false },
        { question: 'How is the WhatsApp Business Platform different from the Messenger Platform?', answer: ' The WhatsApp Business API is specific to messaging with your customers on WhatsApp. Messages sent on the Messenger Platform cannot reach customers on their WhatsApp numbers. You can learn more about the Messenger Platform here.', isOpen: false },
        { question: 'How is the WhatsApp Business Platform different from the WhatsApp Business app?', answer: ' The WhatsApp Business app ​​is for small businesses to communicate with customers from a single device through a free-to-download app. The Business app enables businesses to reach hundreds of customers, is free to use and offers limited customization and integration with other tools.', isOpen: false },
        { question: 'What are the options for integrating with the WhatsApp Business Platform?', answer: ' Business can choose from  Directly accessing the WhatsApp Business Platform by signing up here. You must be a developer in order to do so as this requires being able to call our APIs and set up webhooks.Accessing the Platform by working with one of our approved Business Solution Providers.', isOpen: false },
        { question: 'How should a business decide to use the platform directly, or to work with an integration partner?', answer: '  Businesses with in-house developers who can directly call our APIs may be interested in signing up directly. If you are not a developer, or would like to connect WhatsApp to the rest of your technology stack, such as an omnichannel inbox, customer support software, or a bot platform, you may prefer to work with one of our partners.', isOpen: false },
        { question: 'Can a developer sign up and offer platform access to other businesses?', answer: ' At this time, developers can access our APIs on behalf of their own business only. If you are interested in offering API access to other businesses, or becoming a partner, please join our waitlist.', isOpen: false },
        { question: 'Is it possible to use an on-premises API, if we don’t want to use the Cloud API?', answer: ' If you are interested in an on-premises option, please work with one of our partners. We are not enabling direct access to the on-premises API.', isOpen: false },
      ];
    
      const [faqItems, setFAQItems] = useState(initialFAQState);
    
      const toggleFAQ = (index) => {
        setFAQItems((prevItems) => {
          return prevItems.map((item, i) => ({
            ...item,
            isOpen: i === index ? !item.isOpen : false,
          }));
        });
      };
    
    
    return (
        <>
   
        <div className='mainfaq' id='FAQ'>
           <div className='faqtext'>
           <h2>FAQs</h2>
           </div>
        {faqItems.map((item, index) => (
       <div key={index} className={`faq-item ${item.isOpen ? 'open' : ''}`} onClick={() => toggleFAQ(index)}>
          <div className="question">
            <span className="text">{item.question}</span>
            <span className={`arrow ${item.isOpen ? 'open' : ''}`}>{item.isOpen ? '▼' : '▲'}</span>
          </div>
          {item.isOpen && (
            <div className="answer">
              <p>{item.answer}</p>
            </div>
          )}
        </div>
      ))}
      </div>
        </>
            
            )
}

            export default FAQs;