import React, { useState } from 'react';
import { AiOutlineMessage } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  return (
    <div>
      <div className='backgroundgif'></div>

      <div className={`navbar ${isNavOpen ? 'open' : ''}`}>
       <Link to="/" className='Linking'>
       <div className='lo'>
          <div className="logo-create">
            <div className="logoboxs">
              <div className='Unlimiteds'>
                <AiOutlineMessage className='messageboxs' />
              </div>
              <div className="logotexts">
                <p><b>BOT</b>WALA</p>
              </div>
            </div>
          </div>
        </div>
       </Link>
        <div className={`nav ${isNavOpen ? 'opens' : ''}`}>
        {/* <div className='lo'>
          <div className="logo-create">
            <div className="logoboxs">
              <div className='Unlimiteds'>
                <AiOutlineMessage className='messageboxs' />
              </div>
              <div className="logotexts">
                <p><b>BOT</b>WALA</p>
              </div>
            </div>
          </div>
        </div> */}
          <Link to="pricing" className='Linking' onClick={toggleNav}>
            Pricing
          </Link>
          <Link to="/feature"  className='Linking' onClick={toggleNav}>
            Features
          </Link>
          <Link to="case" className='Linking' id='CaseStudies' onClick={toggleNav}>
            Case Studies
          </Link>
          <Link to="/partner" className='Linking' onClick={toggleNav}>
            Become A Partner
          </Link>
          <Link to="faq" className='Linking' onClick={toggleNav}>
            FAQs
          </Link>
          <div className={`buttons ${isNavOpen ? 'opens' : ''}`}>
          <div className='btn1'>
            {/* <Link to='/login' className='Linking lbtn' onClick={toggleNav}>
              <button>Login</button>
            </Link> */}
            <a href='https://crm.botwala.com/user/login' onClick={toggleNav}> <button>Login</button></a>
          </div>
          <div className='btn2'>
          <a href='https://crm.botwala.com/user/login' className='Linking lbtn' onClick={toggleNav}>  <button>Free-Trial</button></a>
            {/* <Link to='/getpage' className='Linking lbtn' onClick={toggleNav}>
              <button>Free-Trial</button>
            </Link> */}
          </div>
        </div>
        </div>
       

        <div className='hamburger-icon' onClick={toggleNav}>
          <div className={`line ${isNavOpen ? 'open' : ''}`}></div>
          <div className={`line ${isNavOpen ? 'open' : ''}`}></div>
          <div className={`line ${isNavOpen ? 'open' : ''}`}></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
