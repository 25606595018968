import React from 'react'
import '../components/RefundPolicy.css'
import { IoShieldCheckmarkSharp } from "react-icons/io5";


const RefundPolicy = () => {
    return (
        <div>
            <div className='refund-policy-page'>
                <div className='refund-head'>
                <h1>Refund Policy</h1>
                </div>
                <div className='refund-policy-box'>
                   
                    <div className='refund-content'>
                        <p>Botwala's commitment to excellence begins with ensuring your satisfaction through transparent and fair policies, such as our refund policy, which provides clear guidelines on handling refund requests.</p>

                        <div className='policy-items'>
                            <h5>Refund Process:-</h5>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> To initiate a refund, please reach out to our Botwala customer support team at support@botwala.com within the specified refund period.</p>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> Clearly state the reason for your refund request and furnish any essential supporting documentation for prompt and efficient processing.</p>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> The Botwala team will carefully review your request and provide a response within 2 business days.</p>
                        </div>
                        <div className='policy-items'>
                            <h5>Policy Updates:-</h5>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> Botwala reserves the right to update or modify its refund policy at any time. Any changes will be promptly communicated on this page.</p>
                        </div>
                        <div className='policy-items'>
                            <h5>Eligibility For Refund:-</h5>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> For refund eligibility, the service should not have been fully utilized or concluded during the specified refund period.</p>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> To be eligible for a refund, requests must be submitted within a 2-day timeframe from the date of service purchase.</p>
                        </div>
                        <div className='policy-items'>
                            <h5>Grounds For Refund:-</h5>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> In situations where Botwala is unable to fulfill the service as initially promised or agreed upon, we acknowledge instances of service unavailability.</p>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> Technical Issues: If the service is impeded by technical problems on our end that hinder its proper delivery, you may be eligible for a refund.</p>
                        </div>
                        <div className='policy-items'>
                            <h5>Refund Approval:-</h5>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> If your refund request is approved, the refund will be processed through the original payment method used for the transaction.</p>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> Please note that the processing time for refunds, typically taking 2 business days, may vary based on your payment provider's policies and procedures.</p>
                        </div>
                        <div className='policy-items'>
                            <h5>Non-Refundable Items:-</h5>
                            <p><IoShieldCheckmarkSharp className='refund-check' /> Non-refundable terms for specific services will be communicated transparently at the time of purchase to ensure clear understanding.</p>
                        </div>
                        <div className='policy-items'>

                            <p className='end-refund'>Botwala values your trust and is committed to delivering exceptional service. If you have any concerns or issues not covered by this policy, please contact us, and we will collaborate with you to find a satisfactory resolution.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default RefundPolicy
